// Body
$body-bg: #f8fafc;

.elmasa-blue { color: #00778b !important; }
.bg-elmasa-blue { background-color: #00778b !important; }
.elmasa-gray { color: #7C878E !important; }
.bg-elmasa-gray { background-color: #7C878E !important; }

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Tailwind Colors

.gray-50 { color: #FAFAFA !important; }
.gray-100 { color: #F4F4F5 !important; }
.gray-200 { color: #E4E4E7 !important; }
.gray-300 { color: #D4D4D8 !important; }
.gray-400 { color: #A1A1AA !important; }
.gray-500 { color: #71717A !important; }
.gray-600 { color: #52525B !important; }
.gray-700 { color: #3F3F46 !important; }
.gray-800 { color: #27272A !important; }
.gray-900 { color: #18181B !important; }

.red-50 { color: #FEF2F2 !important; }
.red-100 { color: #FEE2E2 !important; }
.red-200 { color: #FECACA !important; }
.red-300 { color: #FCA5A5 !important; }
.red-400 { color: #F87171 !important; }
.red-500 { color: #EF4444 !important; }
.red-600 { color: #DC2626 !important; }
.red-700 { color: #B91C1C !important; }
.red-800 { color: #991B1B !important; }
.red-900 { color: #7F1D1D !important; }

.amber-50 { color: #FFFBEB !important; }
.amber-100 { color: #FEF3C7 !important; }
.amber-200 { color: #FDE68A !important; }
.amber-300 { color: #FCD34D !important; }
.amber-400 { color: #FBBF24 !important; }
.amber-500 { color: #F59E0B !important; }
.amber-600 { color: #D97706 !important; }
.amber-700 { color: #B45309 !important; }
.amber-800 { color: #92400E !important; }
.amber-900 { color: #78350F !important; }

.green-50 { color: #F0FDF4 !important; }
.green-100 { color: #DCFCE7 !important; }
.green-200 { color: #BBF7D0 !important; }
.green-300 { color: #86EFAC !important; }
.green-400 { color: #4ADE80 !important; }
.green-500 { color: #22C55E !important; }
.green-600 { color: #16A34A !important; }
.green-700 { color: #15803D !important; }
.green-800 { color: #166534 !important; }
.green-900 { color: #14532D !important; }

.teal-50 { color: #F0FDFA !important; }
.teal-100 { color: #CCFBF1 !important; }
.teal-200 { color: #99F6E4 !important; }
.teal-300 { color: #5EEAD4 !important; }
.teal-400 { color: #2DD4BF !important; }
.teal-500 { color: #14B8A6 !important; }
.teal-600 { color: #0D9488 !important; }
.teal-700 { color: #0F766E !important; }
.teal-800 { color: #115E59 !important; }
.teal-900 { color: #134E4A !important; }

.cyan-50 { color: #ECFEFF !important; }
.cyan-100 { color: #CFFAFE !important; }
.cyan-200 { color: #A5F3FC !important; }
.cyan-300 { color: #67E8F9 !important; }
.cyan-400 { color: #22D3EE !important; }
.cyan-500 { color: #06B6D4 !important; }
.cyan-600 { color: #0891B2 !important; }
.cyan-700 { color: #0E7490 !important; }
.cyan-800 { color: #155E75 !important; }
.cyan-900 { color: #164E63 !important; }

.blue-50 { color: #EFF6FF !important; }
.blue-100 { color: #DBEAFE !important; }
.blue-200 { color: #BFDBFE !important; }
.blue-300 { color: #93C5FD !important; }
.blue-400 { color: #60A5FA !important; }
.blue-500 { color: #3B82F6 !important; }
.blue-600 { color: #2563EB !important; }
.blue-700 { color: #1D4ED8 !important; }
.blue-800 { color: #1E40AF !important; }
.blue-900 { color: #1E3A8A !important; }


.indigo-50 { color: #EEF2FF !important; }
.indigo-100 { color: #E0E7FF !important; }
.indigo-200 { color: #C7D2FE !important; }
.indigo-300 { color: #A5B4FC !important; }
.indigo-400 { color: #818CF8 !important; }
.indigo-500 { color: #6366F1 !important; }
.indigo-600 { color: #4F46E5 !important; }
.indigo-700 { color: #4338CA !important; }
.indigo-800 { color: #3730A3 !important; }
.indigo-900 { color: #312E81 !important; }

.purple-50 { color: #FAF5FF !important; }
.purple-100 { color: #F3E8FF !important; }
.purple-200 { color: #E9D5FF !important; }
.purple-300 { color: #D8B4FE !important; }
.purple-400 { color: #C084FC !important; }
.purple-500 { color: #A855F7 !important; }
.purple-600 { color: #9333EA !important; }
.purple-700 { color: #7E22CE !important; }
.purple-800 { color: #6B21A8 !important; }
.purple-900 { color: #581C87 !important; }

.pink-50 { color: #FDF2F8 !important; }
.pink-100 { color: #FCE7F3 !important; }
.pink-200 { color: #FBCFE8 !important; }
.pink-300 { color: #F9A8D4 !important; }
.pink-400 { color: #F472B6 !important; }
.pink-500 { color: #EC4899 !important; }
.pink-600 { color: #DB2777 !important; }
.pink-700 { color: #BE185D !important; }
.pink-800 { color: #9D174D !important; }
.pink-900 { color: #831843 !important; }

.bg-gray-50 { background-color: #FAFAFA !important; }
.bg-gray-100 { background-color: #F4F4F5 !important; }
.bg-gray-200 { background-color: #E4E4E7 !important; }
.bg-gray-300 { background-color: #D4D4D8 !important; }
.bg-gray-400 { background-color: #A1A1AA !important; }
.bg-gray-500 { background-color: #71717A !important; }
.bg-gray-600 { background-color: #52525B !important; }
.bg-gray-700 { background-color: #3F3F46 !important; }
.bg-gray-800 { background-color: #27272A !important; }
.bg-gray-900 { background-color: #18181B !important; }

.bg-red-50 { background-color: #FEF2F2 !important; }
.bg-red-100 { background-color: #FEE2E2 !important; }
.bg-red-200 { background-color: #FECACA !important; }
.bg-red-300 { background-color: #FCA5A5 !important; }
.bg-red-400 { background-color: #F87171 !important; }
.bg-red-500 { background-color: #EF4444 !important; }
.bg-red-600 { background-color: #DC2626 !important; }
.bg-red-700 { background-color: #B91C1C !important; }
.bg-red-800 { background-color: #991B1B !important; }
.bg-red-900 { background-color: #7F1D1D !important; }

.bg-amber-50 { background-color: #FFFBEB !important; }
.bg-amber-100 { background-color: #FEF3C7 !important; }
.bg-amber-200 { background-color: #FDE68A !important; }
.bg-amber-300 { background-color: #FCD34D !important; }
.bg-amber-400 { background-color: #FBBF24 !important; }
.bg-amber-500 { background-color: #F59E0B !important; }
.bg-amber-600 { background-color: #D97706 !important; }
.bg-amber-700 { background-color: #B45309 !important; }
.bg-amber-800 { background-color: #92400E !important; }
.bg-amber-900 { background-color: #78350F !important; }

.bg-green-50 { background-color: #F0FDF4 !important; }
.bg-green-100 { background-color: #DCFCE7 !important; }
.bg-green-200 { background-color: #BBF7D0 !important; }
.bg-green-300 { background-color: #86EFAC !important; }
.bg-green-400 { background-color: #4ADE80 !important; }
.bg-green-500 { background-color: #22C55E !important; }
.bg-green-600 { background-color: #16A34A !important; }
.bg-green-700 { background-color: #15803D !important; }
.bg-green-800 { background-color: #166534 !important; }
.bg-green-900 { background-color: #14532D !important; }

.bg-teal-50 { background-color: #F0FDFA !important; }
.bg-teal-100 { background-color: #CCFBF1 !important; }
.bg-teal-200 { background-color: #99F6E4 !important; }
.bg-teal-300 { background-color: #5EEAD4 !important; }
.bg-teal-400 { background-color: #2DD4BF !important; }
.bg-teal-500 { background-color: #14B8A6 !important; }
.bg-teal-600 { background-color: #0D9488 !important; }
.bg-teal-700 { background-color: #0F766E !important; }
.bg-teal-800 { background-color: #115E59 !important; }
.bg-teal-900 { background-color: #134E4A !important; }

.bg-cyan-50 { background-color: #ECFEFF !important; }
.bg-cyan-100 { background-color: #CFFAFE !important; }
.bg-cyan-200 { background-color: #A5F3FC !important; }
.bg-cyan-300 { background-color: #67E8F9 !important; }
.bg-cyan-400 { background-color: #22D3EE !important; }
.bg-cyan-500 { background-color: #06B6D4 !important; }
.bg-cyan-600 { background-color: #0891B2 !important; }
.bg-cyan-700 { background-color: #0E7490 !important; }
.bg-cyan-800 { background-color: #155E75 !important; }
.bg-cyan-900 { background-color: #164E63 !important; }

.bg-blue-50 { background-color: #EFF6FF !important; }
.bg-blue-100 { background-color: #DBEAFE !important; }
.bg-blue-200 { background-color: #BFDBFE !important; }
.bg-blue-300 { background-color: #93C5FD !important; }
.bg-blue-400 { background-color: #60A5FA !important; }
.bg-blue-500 { background-color: #3B82F6 !important; }
.bg-blue-600 { background-color: #2563EB !important; }
.bg-blue-700 { background-color: #1D4ED8 !important; }
.bg-blue-800 { background-color: #1E40AF !important; }
.bg-blue-900 { background-color: #1E3A8A !important; }


.bg-indigo-50 { background-color: #EEF2FF !important; }
.bg-indigo-100 { background-color: #E0E7FF !important; }
.bg-indigo-200 { background-color: #C7D2FE !important; }
.bg-indigo-300 { background-color: #A5B4FC !important; }
.bg-indigo-400 { background-color: #818CF8 !important; }
.bg-indigo-500 { background-color: #6366F1 !important; }
.bg-indigo-600 { background-color: #4F46E5 !important; }
.bg-indigo-700 { background-color: #4338CA !important; }
.bg-indigo-800 { background-color: #3730A3 !important; }
.bg-indigo-900 { background-color: #312E81 !important; }

.bg-purple-50 { background-color: #FAF5FF !important; }
.bg-purple-100 { background-color: #F3E8FF !important; }
.bg-purple-200 { background-color: #E9D5FF !important; }
.bg-purple-300 { background-color: #D8B4FE !important; }
.bg-purple-400 { background-color: #C084FC !important; }
.bg-purple-500 { background-color: #A855F7 !important; }
.bg-purple-600 { background-color: #9333EA !important; }
.bg-purple-700 { background-color: #7E22CE !important; }
.bg-purple-800 { background-color: #6B21A8 !important; }
.bg-purple-900 { background-color: #581C87 !important; }

.bg-pink-50 { background-color: #FDF2F8 !important; }
.bg-pink-100 { background-color: #FCE7F3 !important; }
.bg-pink-200 { background-color: #FBCFE8 !important; }
.bg-pink-300 { background-color: #F9A8D4 !important; }
.bg-pink-400 { background-color: #F472B6 !important; }
.bg-pink-500 { background-color: #EC4899 !important; }
.bg-pink-600 { background-color: #DB2777 !important; }
.bg-pink-700 { background-color: #BE185D !important; }
.bg-pink-800 { background-color: #9D174D !important; }
.bg-pink-900 { background-color: #831843 !important; }

.hover-cyan-50:hover { color: #ECFEFF !important; }
.hover-cyan-100:hover { color: #CFFAFE !important; }
.hover-cyan-200:hover { color: #A5F3FC !important; }
.hover-cyan-300:hover { color: #67E8F9 !important; }
.hover-cyan-400:hover { color: #22D3EE !important; }
.hover-cyan-500:hover { color: #06B6D4 !important; }
.hover-cyan-600:hover { color: #0891B2 !important; }
.hover-cyan-700:hover { color: #0E7490 !important; }
.hover-cyan-800:hover { color: #155E75 !important; }
.hover-cyan-900:hover { color: #164E63 !important; }

.hover-bg-cyan-50:hover { background-color: #ECFEFF !important; }
.hover-bg-cyan-100:hover { background-color: #CFFAFE !important; }
.hover-bg-cyan-200:hover { background-color: #A5F3FC !important; }
.hover-bg-cyan-300:hover { background-color: #67E8F9 !important; }
.hover-bg-cyan-400:hover { background-color: #22D3EE !important; }
.hover-bg-cyan-500:hover { background-color: #06B6D4 !important; }
.hover-bg-cyan-600:hover { background-color: #0891B2 !important; }
.hover-bg-cyan-700:hover { background-color: #0E7490 !important; }
.hover-bg-cyan-800:hover { background-color: #155E75 !important; }
.hover-bg-cyan-900:hover { background-color: #164E63 !important; }